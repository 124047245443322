import React, { useState } from "react";
import classNames from "classnames/bind";

import styles from "./file-input.module.scss";

const cx = classNames.bind(styles);

const FileInput = ({ label, name, placeholder, onChange }) => {
  const [file, setFile] = useState(null);
  const [focus, setFocus] = useState(false);

  const _onChange = (e) => {
    const file = e.target.files[0];
    setFile(file.name);
    onChange(file);
  };

  return (
    <div className={styles.fieldContainer}>
      <div className={styles.inputLabel}>{label}</div>
      <div className={cx(styles.inputContainer, focus && styles.focus)}>
        <input
          type="file"
          name={name || ""}
          onChange={(e) => e && _onChange(e)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          className={styles.input}
        />
        <div className={styles.placeholder}>{file ? file : placeholder}</div>
      </div>
    </div>
  );
};

export default FileInput;
