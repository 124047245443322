import React from "react";
import classNames from "classnames/bind";

import styles from "./list.module.scss";
import Checkmark from "../Checkmark";

const cx = classNames.bind(styles);

const List = ({ title, data, onSelect, filters }) => {
  let items = [];
  for (const key in data) {
    let selected = false;
    if (key === filters.filter && title === filters.group) {
      selected = true;
    }
    items.push(
      <li
        key={key}
        data-index={key}
        className={cx(styles.item, selected && styles.selected)}
        onClick={(e) => onSelect(e.target.getAttribute("data-index"), title)}
      >
        {data[key]}
        {selected && <Checkmark className={styles.position} />}
      </li>
    );
  }

  return (
    <ul className={styles.list}>
      <li className={styles.title}>
        {title.charAt(0).toUpperCase() + title.slice(1)}
      </li>
      {items}
    </ul>
  );
};

export default List;
