import React from "react";
import _ from "lodash";

const ADD_FILTER = "ADD_FILTER";
const REMOVE_FILTER = "REMOVE_FILTER";

export const initialState = { data: {} };

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILTER:
      return {
        ...state,
        data: action.payload,
      };
    case REMOVE_FILTER:
      return {
        data: {},
      };
    default:
      return state;
  }
};

const addFilter = (payload) => ({ type: ADD_FILTER, payload });
export const removeFilter = () => ({ type: REMOVE_FILTER });

export const addRemoveFilter = (data) => (dispatch, state) => {
  const filters = state.data;

  if (_.isEmpty(filters)) {
    dispatch(addFilter(data));
  } else {
    if (filters.group === data.group && filters.filter === data.filter) {
      dispatch(removeFilter());
    } else {
      dispatch(removeFilter());
      dispatch(addFilter(data));
    }
  }
};

export const Context = React.createContext(initialState);
