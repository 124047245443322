import * as ActionTypes from "./actionTypes";

const initialState = {
  paymentIntentId: "",
  job: {
    title: "",
    category: "",
    type: "",
    restrictions: "",
    description: "<p></p>",
    createdAt: "",
    hq: "",
    how_to_apply: "",
  },
  company: {
    name: "",
    statement: "",
    logo: "",
    website: "",
    email: "",
    description: "<p></p>",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SET_JOB:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.GET_SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
