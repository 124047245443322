import React from "react";
import classNames from "classnames/bind";

import Text from "../Text";
import List from "../List";

import styles from "./dropdown.module.scss";

const cx = classNames.bind(styles);

const Dropdown = ({ title, icon, show, data, onClick, onSelect, filters }) => {
  let lists = [];
  for (const key in data) {
    lists.push(
      <List
        key={key}
        title={key}
        data={data[key]}
        onSelect={onSelect}
        filters={filters}
      />
    );
  }

  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={onClick}>
        <Text
          value={title}
          family="cerebriM"
          size={9}
          color="#767676"
          iconLeft={icon}
          className={styles.wrapper}
        />
      </button>
      <div className={cx(styles.body, show && styles.show)}>{lists}</div>
    </div>
  );
};

export default Dropdown;
