import React, { useEffect, useReducer } from "react";
import { Auth, Analytics } from "../../firebase.js";
import { reducer, initialState, Context } from "../../store/FilterStore";
import Header from "../Header";
import Menu from "../Menu";
import Modal from "../Modal";
import Text from "../Text";
import Button from "../Button";

import styles from "./layout.module.scss";
import { toggleModal } from "../../redux/modules/modal/actions";
import { useDispatch, useSelector } from "react-redux";
import { logOut, setUser } from "../../redux/modules/user/actions";

const logIn = (type) => {
  window.open(
    `${process.env.REACT_APP_BASE_API_URL}/auth/${type}/redirect`,
    "firebaseAuth",
    "height=500,width=600"
  );
};

const Layout = ({ children, location }) => {
  const [filterState, filterDispatch] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const visible = useSelector((state) => state.modal.visible);
  const openModal = () => dispatch(toggleModal("loginModal"));
  const closeModal = () => dispatch(toggleModal(null));

  const show = Boolean(location.pathname === "/");

  const toggleScroll = () => {
    if (visible) {
      document.body.classList.add("modalOpen");
    } else {
      document.body.classList.remove("modalOpen");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleScroll);

    Auth().onAuthStateChanged(function (user) {
      if (user) {
        Analytics.setUserId(user.email);
        dispatch(setUser());
        closeModal();
      }
    });
    return () => window.removeEventListener("scroll", toggleScroll);
  }, []);

  return (
    <Context.Provider value={{ store: filterState, dispatch: filterDispatch }}>
      <div className={styles.container}>
        <Header logout={() => dispatch(logOut())} login={openModal} />
        <div className={styles.content} style={{ marginBottom: show ? 50 : 0 }}>
          {children}
        </div>
        {show ? (
          <Menu />
        ) : (
          <div className={styles.copyright}>&copy; 2020 Remote Accounting</div>
        )}
        <Modal visible={visible === "loginModal"} onClose={closeModal}>
          <Text
            value="Remote Accounting Login"
            color="#c08d5d"
            size={18}
            family="cerebriM"
            className={styles.title}
          />
          <Text
            value="continue with "
            color="#0b0b0b"
            size={16}
            family="cerebriL"
          />
          <Button
            label="LinkedIn"
            submitting={false}
            onClick={() => logIn("linkedin")}
            className={styles.button}
          />
          <Button
            label="Google"
            submitting={false}
            onClick={() => logIn("google")}
            className={styles.button}
          />
        </Modal>
      </div>
    </Context.Provider>
  );
};
export default Layout;
