import React from "react";
import classNames from "classnames/bind";

import styles from "./steps.module.scss";

const cx = classNames.bind(styles);

const items = [
  {
    number: 1,
    title: "Post job",
  },
  {
    number: 2,
    title: "Preview add",
  },
  {
    number: 3,
    title: "Purchase",
  },
];

const Steps = ({ step }) => {
  let steps = [];
  items.map((item, i) => {
    const active = Boolean(step === item.number);
    return steps.push(
      <div key={i} className={styles.wrap}>
        <div className={cx(styles.step, active && styles.active)}>
          {item.number}
          <div className={styles.name}>{item.title}</div>
        </div>
      </div>
    );
  });

  return (
    <div className={styles.container}>
      {steps}
      <div className={styles.path} />
    </div>
  );
};

export default Steps;
