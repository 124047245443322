import React from "react";
import { useSelector } from "react-redux";

import Text from "../../../../components/Text";
import Button from "../../../../components/Button";
import JobLabel from "../../../../components/JobLabel";
import CompanyCard from "../../../../components/CompanyCard";

import { ReactComponent as CalendarSVG } from "../../../../assets/images/calendar.svg";

import styles from "./preview-post.module.scss";

const PreviewPost = ({ next, previous, values }) => {
  const categories = useSelector(
    (state) => state.settings.data && state.settings.data.job_categories
  );
  const types = useSelector(
    (state) => state.settings.data && state.settings.data.job_types
  );
  const regions = useSelector(
    (state) => state.settings.data && state.settings.data.regions
  );
  const { job, company } = values;

  return (
    <div>
      <CompanyCard company={company} />

      {job.createdAt && (
        <Text
          value={job.createdAt}
          iconLeft={
            <CalendarSVG
              width="15"
              height="15"
              fill={"#c08d5d"}
              style={{ marginRight: 5 }}
            />
          }
          className={styles.pb10}
        />
      )}

      {job.title && (
        <Text
          value={job.title}
          color="#c08d5d"
          size={22}
          family="cerebriM"
          className={styles.pb15}
        />
      )}

      <div className={styles.row}>
        {job.type && <JobLabel value={types[job.type]} />}
        {job.category && <JobLabel value={categories[job.category]} />}
        {job.restrictions && <JobLabel value={regions[job.restrictions]} />}
      </div>

      {job.description && (
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: job.description }}
        />
      )}

      <div className={styles.buttons}>
        <Button
          label="continue to step 3"
          color="#c08d5d"
          className={styles.button}
          onClick={next}
        />
        <Button
          label="back to step 1"
          className={styles.button}
          onClick={previous}
        />
      </div>
    </div>
  );
};

export default PreviewPost;
