import React from "react";
import classNames from "classnames/bind";

import styles from "./loader.module.scss";

const cx = classNames.bind(styles);

const Loader = ({ color, center }) => {
  const loaderStyles = {
    borderColor: color,
  };
  return (
    <div
      className={cx(styles.loader, center && styles.center)}
      style={loaderStyles}
    ></div>
  );
};

export default Loader;
