import React from "react";
import classNames from "classnames/bind";

import styles from "./checkmark.module.scss";

const cx = classNames.bind(styles);

const Checkmark = ({ className }) => (
  <div className={cx(styles.checkmark, className)}></div>
);

export default Checkmark;
