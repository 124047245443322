import React, { useEffect } from "react";
import { Auth } from "../../firebase";
import { useParams } from "react-router-dom";

const AuthCallback = () => {
  const { token } = useParams();
  useEffect(() => {
    async function parseToken() {
      try {
        Auth()
          .signInWithCustomToken(token)
          .then(() => window.close());
      } catch (error) {
        console.log(error);
      }
    }
    parseToken();
  }, [token]);
  return <div />;
};

export default AuthCallback;
