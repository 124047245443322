import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Loader from "../Loader";

import styles from "./header.module.scss";
import brand from "../../assets/images/brand.svg";
import brandLogo from "../../assets/images/brand_logo.svg";

const LoginButton = ({ login, logout }) => {
  const userLogged = useSelector((state) => state.user.userLogged);
  const isLoading = useSelector((state) => state.user.isLoading);

  return (
    <div className={styles.right}>
      {isLoading ? (
        <Loader color="#c08d5d" />
      ) : userLogged ? (
        <>
          <div onClick={logout} className={styles.button}>
            Logout
          </div>
        </>
      ) : (
        <div onClick={login} className={styles.button}>
          Login
        </div>
      )}
    </div>
  );
};

const NavigationButton = () => {
  let location = useLocation();
  let history = useHistory();
  return (
    <div className={styles.left}>
      {location.pathname.includes("/blog") ? (
        <>
          <div onClick={() => history.push("/")} className={styles.button}>
            Jobs
          </div>
        </>
      ) : (
        <div onClick={() => history.push("/blog")} className={styles.button}>
          Blog
        </div>
      )}
    </div>
  );
};
const Header = ({ logout, login }) => {
  return (
    <div className={styles.container}>
      <NavigationButton />
      <Link to="/">
        <picture>
          <source media="(max-width: 64em)" srcSet={brandLogo} />
          <source media="(min-width: 75em)" srcSet={brand} />
          <img
            src={brand}
            alt="Remote Accounting Logo"
            className={styles.brand}
          />
        </picture>
      </Link>
      <LoginButton logout={logout} login={login} />
    </div>
  );
};

export default Header;
