import { db } from "../../../firebase.js";
import * as ActionTypes from "./actionTypes";
import _ from "lodash";

import { getCompaniesSuccess } from "../companies/actions";
import { FIRESTORE_COLLECTIONS } from "../../../services/constants";

const getJobsStart = () => ({ type: ActionTypes.GET_JOBS_START });
const getJobsSuccess = (payload) => ({
  type: ActionTypes.GET_JOBS_SUCCESS,
  payload,
});
const getJobsFailure = (e) => ({
  type: ActionTypes.GET_JOBS_FAILURE,
  error: e.message,
});

const getJobs = () => async (dispatch) => {
  dispatch(getJobsStart());
  try {
    const snapshot = await db
      .collection("jobs")
      .orderBy("createdAt", "desc")
      .get();
    const companies = {};
    let jobs = await Promise.all(
      snapshot.docs.map(async (jobDoc) => {
        let jobData = jobDoc.data();
        const { companyId } = jobData;
        if (!companyId) {
          return null;
        }
        let company = companies[companyId];
        if (company) {
          return { id: jobDoc.id, ...jobData, company };
        }
        company = await db
          .collection(FIRESTORE_COLLECTIONS.COMPANIES)
          .doc(companyId)
          .get();
        company = company.data();
        companies[companyId] = company;
        return { id: jobDoc.id, ...jobData, company };
      })
    );
    jobs = jobs.filter((job) => !_.isEmpty(job));
    jobs = jobs.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {});
    dispatch(getJobsSuccess(jobs));
    dispatch(getCompaniesSuccess(companies));
  } catch (e) {
    dispatch(getJobsFailure(e));
  }
};

const getJob = (id) => async (dispatch) => {
  try {
    let companies = {};
    let data = {};
    const response = await db.collection("jobs").doc(id).get();
    data[`${response.id}`] = response.data();
    const company = await db
      .collection("companies")
      .doc(data[`${response.id}`].companyId)
      .get();
    const companyId = company.data().uid;
    companies[`${companyId}`] = company.data();
    data[`${response.id}`] = {
      ...data[`${response.id}`],
      ...{ company: company.data() || {} },
    };
    dispatch(getJobsSuccess(data));
  } catch (error) {
    dispatch(getJobsFailure(error));
  }
};

const getCompanyJobs = (companyId) => async (dispatch) => {
  dispatch(getJobsStart());
  try {
    let data = {};
    const response = await db
      .collection("jobs")
      .where("companyId", "==", companyId)
      .get();
    const promises = response.docs.map(
      (doc) =>
        new Promise((resolve, reject) => {
          data[`${doc.id}`] = doc.data();
          db.collection(FIRESTORE_COLLECTIONS.COMPANIES)
            .doc(companyId)
            .get()
            .then((company) => {
              data[`${doc.id}`] = {
                ...data[`${doc.id}`],
                ...{ company: company.data() },
              };
              resolve();
            })
            .catch(() => {
              data[`${doc.id}`] = { ...data[`${doc.id}`], ...{ company: {} } };
              resolve();
            });
        })
    );
    Promise.all(promises).then(() => {
      dispatch(getJobsSuccess(data));
    });
  } catch (error) {
    dispatch(getJobsFailure(error));
  }
};

const getFilterJobsStart = () => ({ type: ActionTypes.GET_FILTER_JOBS_START });
const getFilterJobsSuccess = (payload) => ({
  type: ActionTypes.GET_FILTER_JOBS_SUCCESS,
  payload,
});

const filterJobs = () => async (dispatch, getState) => {
  let jobs = getState().jobs.data;
  const filters = getState().filters.data;

  if (!_.isEmpty(filters)) {
    dispatch(getFilterJobsStart());
    let property = "";

    switch (filters.group) {
      case "regions":
        property = "restrictions";
        break;
      case "categories":
        property = "category";
        break;
      case "types":
        property = "type";
        break;
      case "date":
        property = "createdAt";
        break;
      case "name":
        property = "title";
        break;
      default:
        break;
    }

    if (
      property === "restrictions" ||
      property === "category" ||
      property === "type"
    ) {
      for (const key in jobs) {
        delete jobs[key].hidden;
        if (jobs[key][property] !== filters.filter) {
          jobs[key].hidden = true;
        } else {
          jobs[key].hidden = false;
        }
      }
    } else if (property === "createdAt") {
      let arr,
        jobsByDate = {};
      switch (filters.filter) {
        case "1":
          arr = [
            ...Object.entries(jobs).sort(
              (x, y) => y[1].createdAt.seconds - x[1].createdAt.seconds
            ),
          ];
          break;
        case "2":
          arr = [
            ...Object.entries(jobs).sort(
              (x, y) => x[1].createdAt.seconds - y[1].createdAt.seconds
            ),
          ];
          break;
        default:
          break;
      }
      arr.map((job) => {
        delete job[1].hidden;
        return (jobsByDate[job[0]] = { ...job[1] });
      });
      jobs = { ...{}, ...jobsByDate };
    } else if (property === "title") {
      let arr,
        jobsByTitle = {};
      switch (filters.filter) {
        case "1":
          arr = [
            ...Object.entries(jobs).sort((x, y) => {
              const nameX = x[1].title.toUpperCase();
              const nameY = y[1].title.toUpperCase();
              let comparison = 0;
              if (nameX > nameY) {
                comparison = 1;
              } else if (nameX < nameY) {
                comparison = -1;
              }
              return comparison;
            }),
          ];
          break;
        case "2":
          arr = [
            ...Object.entries(jobs).sort((x, y) => {
              const nameX = x[1].company.name.toUpperCase();
              const nameY = y[1].company.name.toUpperCase();
              let comparison = 0;
              if (nameX > nameY) {
                comparison = 1;
              } else if (nameX < nameY) {
                comparison = -1;
              }
              return comparison;
            }),
          ];
          break;
        default:
          break;
      }
      arr.map((job) => {
        delete job[1].hidden;
        return (jobsByTitle[job[0]] = { ...job[1] });
      });
      jobs = { ...{}, ...jobsByTitle };
    }
    dispatch(getFilterJobsSuccess(jobs));
  } else {
    dispatch(getJobs());
  }
};

export { getJobs, getJob, getCompanyJobs, filterJobs };
