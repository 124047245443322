import React from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";

import Text from "../Text";
import Loader from "../Loader";

import styles from "./button.module.scss";

const cx = classNames.bind(styles);

const Button = ({
  label,
  family,
  color,
  size,
  type,
  className,
  onClick,
  to,
  submitting,
  disabled,
}) => {
  if (to) {
    return (
      <Link to={to} className={cx(styles.button, className)} onClick={onClick}>
        <Text
          value={label}
          family={family || "cerebriM"}
          size={size || 18}
          color={color || "#0b0b0b"}
        />
      </Link>
    );
  }

  return (
    <button
      type={type}
      className={cx(styles.button, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {submitting ? (
        <Loader color="#000000" />
      ) : (
        <Text
          value={label}
          family={family || "cerebriM"}
          size={size || 18}
          color={color || "#0b0b0b"}
        />
      )}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  onClick: () => ({}),
};

export default Button;
