import React from "react";

import Text from "../Text";

import styles from "./select.module.scss";

const Select = ({ value, label, name, options, onChange, required }) => {
  let listOptions = [];
  for (const key in options) {
    listOptions.push(
      <option key={key} value={key}>
        {options[key]}
      </option>
    );
  }

  return (
    <div className={styles.selectContainer}>
      <div className={styles.selectLabel}>
        <Text value={label} size={14} color="#0b0b0b" family="cerebriM" />
        {required && <Text value="*" color="#c08d5d" family="cerebriM" />}
      </div>
      <select
        value={value}
        name={name}
        className={styles.select}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value=""></option>
        {listOptions}
      </select>
    </div>
  );
};

export default Select;
