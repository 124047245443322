import { db, Auth } from "../../../firebase.js";
import * as ActionTypes from "./actionTypes";
import { FIRESTORE_COLLECTIONS } from "../../../services/constants";

const setUserStart = () => ({ type: ActionTypes.SET_USER_START });
const setUserSuccess = (payload) => ({
  type: ActionTypes.SET_USER_SUCCESS,
  payload,
});
const setUserFailure = () => ({ type: ActionTypes.SET_USER_FAILURE });

const logIn = (type) => async () => {
  window.open(
    `${process.env.REACT_APP_BASE_API_URL}/auth/${type}/redirect`,
    "firebaseAuth",
    "height=500,width=600"
  );
};

const logOut = () => (dispatch) => {
  Auth()
    .signOut()
    .then(function () {
      dispatch({ type: ActionTypes.LOGOUT });
    })
    .catch(function (error) {
      console.log(error);
    });
};

const setUser = () => async (dispatch) => {
  dispatch(setUserStart());
  let currentUser = Auth().currentUser;
  try {
    const user = await db
      .collection(FIRESTORE_COLLECTIONS.USERS)
      .doc(currentUser.uid)
      .get();
    dispatch(setUserSuccess({ data: user.data() }));
  } catch (error) {
    dispatch(setUserFailure());
  }
};

export { logIn, logOut, setUser };
