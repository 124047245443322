import * as ActionTypes from "./actionTypes";

const initialState = {
  data: null,
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_SUBSCRIPTION_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case ActionTypes.CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.POST_JOB_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.POST_JOB_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case ActionTypes.POST_JOB_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
