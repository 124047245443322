import React from "react";
import ArticleSummary from "./article-summary";
import styles from "./blog-index-page.module.scss";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import { useFirestoreQuery } from "../../hooks";
import { db } from "../../firebase";
import Loader from "../../components/Loader";
import { FIRESTORE_COLLECTIONS } from "../../services/constants";
import _ from "lodash";
import Feedback from "../../components/Feedback";

function BlogIndexPage({ blogRoot = "/blog" }) {
  const { data: articles, status } = useFirestoreQuery(
    db.collection(FIRESTORE_COLLECTIONS.ARTICLES).orderBy("createdAt", "desc")
  );
  return (
    <Layout location={blogRoot}>
      <SEO title="All remote jobs" />
      <div className={styles.list}>
        {status === "loading" ? (
          <Loader color="#c08d5d" />
        ) : !_.isEmpty(articles) ? (
          Object.values(articles).map((article) => (
            <ArticleSummary
              key={article.id}
              article={{
                ...article,
                href: `/blog/${article.id}`,
              }}
            />
          ))
        ) : (
          <Feedback value="There are no posts at the moment" />
        )}
      </div>
    </Layout>
  );
}

export default BlogIndexPage;
