import React from "react";
import { useSelector } from "react-redux";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import classNames from "classnames/bind";

import { storage } from "../../../../firebase.js";
import { setCreatedDate } from "../../../../helpers/formats";

import styles from "./create-post.module.scss";

import Text from "../../../../components/Text";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import Textarea from "../../../../components/Textarea";
import FileInput from "../../../../components/FileInput";
import { generateGuid } from "../../../../services/utils";

const cx = classNames.bind(styles);

const validationSchema = Yup.object().shape({
  job: Yup.object().shape({
    title: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    restrictions: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    howToApply: Yup.string().required("Required"),
  }),
  company: Yup.object().shape({
    name: Yup.string().required("Required"),
    hq: Yup.string().required("Required"),
    website: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
  }),
});

const CreatePost = ({ next, submit, initialValues }) => {
  const categories = useSelector(
    (state) => state.settings.data && state.settings.data.job_categories
  );
  const types = useSelector(
    (state) => state.settings.data && state.settings.data.job_types
  );
  const regions = useSelector(
    (state) => state.settings.data && state.settings.data.regions
  );

  const onSubmit = (values) => {
    values.job.createdAt = setCreatedDate();
    submit(values);
    next();
  };

  const uploadLogo = async (file) => {
    const storageRef = storage.ref();
    const snapshot = await storageRef
      .child(`logos/${generateGuid()}`)
      .put(file);
    return snapshot.ref.getDownloadURL();
  };

  return (
    <div>
      <div
        className={cx(
          styles.row,
          styles.space_between,
          styles.pb10,
          styles.rowInMobile
        )}
      >
        <Text
          value="First, tell us about the position"
          size={16}
          color="#c08d5d"
          family="cerebriM"
        />
        <div className={styles.row}>
          <Text
            value="Required fields"
            size={14}
            color="#0b0b0b"
            family="cerebriL"
          />
          <Text value="*" color="#c08d5d" family="cerebriM" />
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({
          handleChange,
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <div>
              <Input
                name="job.title"
                value={values.job.title}
                label="Index Title"
                onChange={handleChange("job.title")}
                required
              />
              <Text
                value={<ErrorMessage name="job.title" />}
                color="#ff0000"
                size={12}
                family="cerebriL"
              />
              <Select
                name="job.category"
                value={values.job.category}
                label="Index Category"
                options={categories}
                onChange={(value) => setFieldValue("job.category", value)}
                required
              />
              <Text
                value={<ErrorMessage name="job.category" />}
                color="#ff0000"
                size={12}
                family="cerebriL"
              />
              <Select
                name="job.type"
                value={values.job.type}
                label="Index Type"
                options={types}
                onChange={(value) => setFieldValue("job.type", value)}
                required
              />
              <Text
                value={<ErrorMessage name="job.type" />}
                color="#ff0000"
                size={12}
                family="cerebriL"
              />
              <Input
                name="company.hq"
                value={values.company.hq}
                label="Company HQ"
                onChange={handleChange("company.hq")}
                required
              />
              <Text
                value={<ErrorMessage name="company.hq" />}
                color="#ff0000"
                size={12}
                family="cerebriL"
              />
              <Select
                name="job.restrictions"
                value={values.job.restrictions}
                label="Geo Restrictions"
                options={regions}
                onChange={(value) => setFieldValue("job.restrictions", value)}
                required
              />
              <Text
                value={<ErrorMessage name="job.restrictions" />}
                color="#ff0000"
                size={12}
                family="cerebriL"
              />
              <Input
                name="job.howToApply"
                value={values.job.howToApply}
                label="How to apply"
                onChange={handleChange("job.howToApply")}
                required
              />
              <Text
                value={<ErrorMessage name="job.howToApply" />}
                color="#ff0000"
                size={12}
                family="cerebriL"
              />
              <Textarea
                name="job.description"
                value={values.job.description}
                label="Index Description"
                onChange={(value) => setFieldValue("job.description", value)}
                required
              />
              <Text
                value={<ErrorMessage name="job.description" />}
                color="#ff0000"
                size={12}
                family="cerebriL"
              />
              <Text
                value="Tell Us More About Your Company"
                size={16}
                color="#c08d5d"
                family="cerebriM"
                className={cx(styles.pb10, styles.pt20)}
              />
              <Input
                name="company.name"
                value={values.company.name}
                label="Company Name"
                onChange={handleChange("company.name")}
                required
              />
              <Text
                value={<ErrorMessage name="company.name" />}
                color="#ff0000"
                size={12}
                family="cerebriL"
              />
              <Input
                name="company.statement"
                value={values.company.statement}
                label="Company statement"
                onChange={handleChange("company.statement")}
              />
              <FileInput
                name="company.logo"
                label="Logo"
                placeholder="Click for the upload your Logo"
                onChange={async (file) => {
                  const logoUrl = await uploadLogo(file);
                  setFieldValue("company.logo", logoUrl);
                }}
              />
              <Input
                name="company.website"
                value={values.company.website}
                label="Website"
                onChange={handleChange("company.website")}
                required
              />
              <Text
                value={<ErrorMessage name="company.website" />}
                color="#ff0000"
                size={12}
                family="cerebriL"
              />
              <Input
                name="company.email"
                value={values.company.email}
                label="Email"
                onChange={handleChange("company.email")}
                required
              />
              <Text
                value={<ErrorMessage name="company.email" />}
                color="#ff0000"
                size={12}
                family="cerebriL"
              />
              <Textarea
                name="company.description"
                value={values.company.description}
                label="Company Description"
                onChange={(value) =>
                  setFieldValue("company.description", value)
                }
              />
              <Button
                label="continue to step 2"
                color="#c08d5d"
                submitting={isSubmitting}
                className={styles.button}
                onClick={() => handleSubmit()}
              />
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreatePost;
