import React, { useEffect } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getCompanies } from "../../redux/modules/companies/actions";
import { getCompanyJobs } from "../../redux/modules/jobs/actions";

import { ReactComponent as MailSVG } from "../../assets/images/mail.svg";
import { ReactComponent as WebsiteSVG } from "../../assets/images/website.svg";

import Layout from "../../components/Layout";
import Text from "../../components/Text";
import Logo from "../../components/Logo";
import Job from "../../components/Job";
import Avatar from "../../components/Avatar";
import Loader from "../../components/Loader";
import Feedback from "../../components/Feedback";

import styles from "./company.module.scss";

const Company = ({ ...props }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const companies = useSelector((state) => state.companies.data);
  const company = companies[id];
  const jobs = useSelector((state) => state.jobs.data);
  const loading = useSelector((state) => state.jobs.isLoading);

  let companyJobs = {};
  for (const job in jobs) {
    if (jobs[job].companyId === id) {
      companyJobs[job] = jobs[job];
    }
  }

  let items = [];
  for (const job in companyJobs) {
    items.push(<Job key={job} id={job} {...companyJobs[job]} />);
  }

  useEffect(() => {
    if (_.isEmpty(companies)) {
      dispatch(getCompanies());
    }
    if (_.isEmpty(jobs)) {
      dispatch(getCompanyJobs(id));
    }
  }, [id, jobs]);

  return (
    <Layout {...props}>
      {!company ? (
        <Loader color="#c08d5d" center />
      ) : (
        <div className={styles.container}>
          {company.logo ? (
            <Logo src={company.logo} className={styles.mb10} />
          ) : (
            <Avatar name={company.name} size="md" className={styles.mb10} />
          )}

          <Text
            value={company.name}
            color="#c08d5d"
            size={22}
            family="cerebriM"
            className={styles.pb15}
          />

          <Text
            value={company.statement}
            color="#0b0b0b"
            size={16}
            family="cerebriL"
            className={styles.pb15}
          />

          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: company.description }}
          />

          <a
            href={company.website}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <Text
              value={company.website}
              color="#0b0b0b"
              size={14}
              family="cerebriL"
              iconLeft={
                <WebsiteSVG
                  width="17"
                  height="15"
                  fill={"#c08d5d"}
                  style={{ marginRight: 5 }}
                />
              }
            />
          </a>

          <a href={`mailto:${company.email}`} className={styles.link}>
            <Text
              value={company.email}
              color="#0b0b0b"
              size={14}
              family="cerebriL"
              iconLeft={
                <MailSVG
                  width="18"
                  height="13"
                  fill={"#c08d5d"}
                  style={{ marginRight: 5 }}
                />
              }
            />
          </a>

          <div className={styles.list}>
            {loading ? (
              <Loader color="#c08d5d" />
            ) : items.length > 0 ? (
              items
            ) : (
              <Feedback value="The company has no active jobs." />
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Company;
