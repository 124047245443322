export let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatDate(dateString) {
  let date = new Date(dateString);
  let monthName = months[date.getMonth()];
  let dayOfMonth = date.getDate();
  let fourDigitYear = date.getFullYear();

  return `${monthName} ${dayOfMonth}, ${fourDigitYear}`;
}

export const setCreatedDate = (milliseconds) => {
  let now;
  if (milliseconds) {
    now = new Date(milliseconds);
  } else {
    now = new Date();
  }
  const month = now.getMonth();
  const day = now.getDate();
  return `${day} ${months[month]}`;
};
