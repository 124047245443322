import * as ActionTypes from "./actionTypes";

const initialState = {
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COMPANIES_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ActionTypes.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null,
      };
    case ActionTypes.GET_COMPANIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
