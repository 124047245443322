import * as ActionTypes from "./actionTypes";

const getSetJob = (payload) => ({ type: ActionTypes.GET_SET_JOB, payload });

const setJob = (data) => async (dispatch) => {
  dispatch(getSetJob(data));
};

const getSetCompany = (payload) => ({
  type: ActionTypes.GET_SET_COMPANY,
  payload,
});

export { getSetCompany, setJob };
