import React from "react";

import Text from "../Text";

const Feedback = ({ value }) => (
  <Text
    value={value}
    color="#0b0b0b"
    size={14}
    family="cerebriL"
    align="center"
  />
);

export default Feedback;
