import { getToken } from "../services/utils";
import { logOut } from "../redux/modules/user/actions";

/* eslint-disable */
const rxOne = /^[\],:{}\s]*$/;
const rxTwo = /\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g;
const rxThree = /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g;
const rxFour = /(?:^|:|,)(?:\s*\[)+/g;
const isJSON = (input) =>
  input.length &&
  rxOne.test(
    input.replace(rxTwo, "@").replace(rxThree, "]").replace(rxFour, "")
  );

const parseJSON = (response) =>
  response.text().then((text) => (isJSON(text) ? JSON.parse(text) : {}));

class BaseDAO {
  defaults = {
    headers: {
      Accept: "application/json",
    },
    apiUrl: process.env.API_BASE_URL,
    endpoint: "",
    authorize: true,
    useFormData: false,
    responseBlob: false,
  };

  constructor(props) {
    this.defaults = { ...this.defaults, ...props };
  }

  joinUrl(...parts) {
    return parts
      .map((part) =>
        part.substr(-1) === "/" // eslint-disable-line no-confusing-arrow
          ? part.substr(0, part.length - 1)
          : part
      )
      .filter((i) => i.length !== 0)
      .join("/");
  }

  apiRequest = async (props) => {
    props = { ...this.defaults, ...props };
    try {
      let headers = { ...props.headers };

      if (props.authorize) {
        headers.authorization = `Bearer ${await getToken()}`;
      }

      if (!props.useFormData) {
        headers["Content-Type"] = "application/json";
      }

      let url = this.joinUrl(props.apiUrl, props.endpoint, props.url);

      if (props.query) {
        url += `?${props.query}`;
      }

      const request = await fetch(url, {
        mode: "cors",
        method: props.method,
        headers,
        body: this.getBody(props),
      });

      if (!request.ok) {
        const { status } = request;

        const response = await request.json();

        if (status === 401 || status === 403) {
          logOut();
          return;
        }

        throw response;
      }
      if (props.responseBlob) return request.blob();
      return parseJSON(request);
    } catch (err) {
      throw err;
    }
  };

  getBody(props) {
    if (props.useFormData) {
      const formData = new FormData();

      for (const key of Object.keys(props.data)) {
        formData.append(key, props.data[key]);
      }

      return formData;
    }

    return JSON.stringify(props.data);
  }
}

export default BaseDAO;
