import React, { useState, useContext } from "react";
import onClickOutside from "react-onclickoutside";
import { Context, addRemoveFilter } from "../../store/FilterStore";
import { categories, regions, types } from "../../helpers/job";
import Dropdown from "../Dropdown";

import { ReactComponent as MenuSVG } from "../../assets/images/menu.svg";
import { ReactComponent as SortSVG } from "../../assets/images/sort.svg";

import styles from "./menu.module.scss";

const sort = {
  date: {
    1: "Newest",
    2: "Oldest",
  },
  name: {
    1: "Index Title",
    2: "Company",
  },
};

const Menu = () => {
  const { store, dispatch } = useContext(Context);
  const [show, setShow] = useState("");
  const toggle = (menu) => {
    setShow("");
    if (show === menu) {
      return setShow("");
    }
    setShow(menu);
  };

  const toggleFilter = (filter, group) => {
    addRemoveFilter({
      group: group,
      filter: filter,
    })(dispatch, store);
    toggle();
  };

  Menu.handleClickOutside = () => toggle();

  return (
    <div className={styles.container}>
      <Dropdown
        title="REFINE"
        icon={
          <MenuSVG
            width="17"
            height="12"
            fill={"#c08d5d"}
            style={{ marginRight: 5 }}
          />
        }
        show={show === "refine"}
        onClick={() => toggle("refine")}
        onSelect={toggleFilter}
        data={{
          regions: regions,
          categories: categories,
          types: types,
        }}
        filters={store.data}
      />

      <Dropdown
        title="SORT"
        icon={
          <SortSVG
            width="15"
            height="15"
            fill={"#c08d5d"}
            style={{ marginRight: 5 }}
          />
        }
        show={show === "sort"}
        onSelect={toggleFilter}
        onClick={() => toggle("sort")}
        data={sort}
        filters={store.data}
      />
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Menu.handleClickOutside,
};

export default onClickOutside(Menu, clickOutsideConfig);
