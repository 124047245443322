import React from "react";
import classNames from "classnames/bind";

import Button from "../../components/Button";

import styles from "./modal.module.scss";

const cx = classNames.bind(styles);

const Modal = ({ children, visible, onClose }) => {
  return (
    <div className={cx(styles.container, visible && styles.visible)}>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={styles.modal}>
        <div className={styles.wrapper}>
          <Button
            label="X"
            submitting={false}
            onClick={onClose}
            className={styles.button}
          ></Button>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
