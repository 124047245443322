import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import classNames from "classnames/bind";
import _ from "lodash";

import Text from "../../../../components/Text";
import Button from "../../../../components/Button";

import styles from "./form.module.scss";
import { ApiDAO } from "../../../../dao";
import { Analytics, firebase } from "../../../../firebase";

const cx = classNames.bind(styles);

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: "#0b0b0b",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      lineHeight: "35px",
      padding: "0 8px",
      "::placeholder": {
        color: "rgba(11,11,11, .5)",
        fontSize: "16px",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const PaymentForm = ({ previous }) => {
  const elements = useElements();
  const stripe = useStripe();
  const history = useHistory();
  const user = useSelector((state) => state.user.data);
  const job = useSelector((state) => state.job);
  const [name, setName] = useState("");
  const [subscribing, setSubscribing] = useState(false);
  const [postal, setPostal] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [focus, setFocus] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (subscribing) {
      return;
    }

    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    //
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name,
        email: user.email,
        address: {
          postal_code: postal,
        },
      },
    });

    if (error) {
      return displayErrorMessage(error);
    }
    const subscription = await ApiDAO.createSubscription({
      paymentMethodId: paymentMethod.id,
      email: user.email,
    });
    if (!subscription || subscription.errors) {
      return displayErrorMessage(
        _.get(subscription, "errors.[0]", {
          message: "Could not process the request",
        })
      );
    }

    await handlePaymentVerificationAndPostJob(subscription, paymentMethod.id);
  };

  const handlePaymentVerificationAndPostJob = async (subscription) => {
    const { payment_intent } = _.get(subscription, "latest_invoice", {});
    if (!payment_intent) {
      return displayErrorMessage({ message: "Could not process payment" });
    }
    const { client_secret, status } = payment_intent;

    if (status !== "requires_action") {
      job.paymentIntentId = payment_intent.id;
      return postJob(job);
    }
    const { error } = await stripe.confirmCardPayment(client_secret);
    if (error) {
      return displayErrorMessage(error);
    }

    job.paymentIntentId = payment_intent.id;
    return postJob(job);
  };

  async function postJob(job) {
    const response = await ApiDAO.postJob(job);
    if (!response || response.errors) {
      return displayErrorMessage(
        _.get(response, "errors.[0]", {
          message: "Could not process the request",
        })
      );
    }
    Analytics.logEvent(firebase.analytics.EventName.PURCHASE);
    history.push(`/job/${response.jobId}`);
  }
  function displayErrorMessage(error) {
    console.log("[createPaymentMethod error]", error);
    setSubscribing(false);
    setErrorMessage(error && error.message);
  }

  return (
    <div>
      <div
        className={cx(
          styles.row,
          styles.spaceBetween,
          styles.pb10,
          styles.rowInMobile
        )}
      >
        <Text
          value="Billing details"
          size={16}
          color="#c08d5d"
          family="cerebriM"
        />
        <div className={styles.row}>
          <Text
            value="Required fields"
            size={14}
            color="#0b0b0b"
            family="cerebriL"
          />
          <Text value="*" color="#c08d5d" family="cerebriM" />
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className={cx(styles.wrapper, styles.w100)}>
          <label htmlFor="name" className={styles.label}>
            Card Holder<span>*</span>
          </label>
          <input
            id="name"
            required
            placeholder="Jenny Rosen"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
          />
        </div>
        <div className={cx(styles.wrapper, styles.w100)}>
          <label htmlFor="cardNumber" className={styles.label}>
            Card Number<span>*</span>
          </label>
          <CardNumberElement
            id="cardNumber"
            onBlur={() => setFocus("")}
            onFocus={() => setFocus("cardNumber")}
            options={ELEMENT_OPTIONS}
            className={cx(
              styles.element,
              focus === "cardNumber" && styles.elementOnFocus
            )}
          />
        </div>
        <div
          className={cx(
            styles.row,
            styles.spaceBetween,
            styles.alignItemsToEnd
          )}
        >
          <div className={cx(styles.wrapper, styles.w30)}>
            <label htmlFor="expiry" className={styles.label}>
              Card Expiration<span>*</span>
            </label>
            <CardExpiryElement
              id="expiry"
              onBlur={() => setFocus("")}
              onFocus={() => setFocus("expiry")}
              options={ELEMENT_OPTIONS}
              className={cx(
                styles.element,
                focus === "expiry" && styles.elementOnFocus
              )}
            />
          </div>
          <div className={cx(styles.wrapper, styles.w30)}>
            <label htmlFor="cvc" className={styles.label}>
              CVC<span>*</span>
            </label>
            <CardCvcElement
              id="cvc"
              onBlur={() => setFocus("")}
              onFocus={() => setFocus("cvc")}
              options={ELEMENT_OPTIONS}
              className={cx(
                styles.element,
                focus === "cvc" && styles.elementOnFocus
              )}
            />
          </div>
          <div className={cx(styles.wrapper, styles.w30)}>
            <label htmlFor="postal" className={styles.label}>
              Postal Code<span>*</span>
            </label>
            <input
              id="postal"
              required
              placeholder="12345"
              value={postal}
              onChange={(e) => setPostal(e.target.value)}
              className={styles.input}
            />
          </div>
        </div>

        <div
          className={cx(
            styles.row,
            styles.spaceBetween,
            styles.pb25,
            styles.pt30,
            styles.rowInMobile
          )}
        >
          <div>
            {errorMessage && (
              <div className={cx(styles.feedback, styles.error)}>
                {errorMessage}
              </div>
            )}
          </div>
          <div className={styles.row}>
            <Text
              value="Your total:"
              size={16}
              color="#000000"
              family="cerebriM"
            />
            <Text
              value="100$"
              size={16}
              color="#c08d5d"
              family="cerebriM"
              className={styles.ml5}
            />
          </div>
        </div>

        <div className={styles.note}>
          You’ll be charged <span>$100 every 30 days</span> while this job is
          active. After each renewal, your ad will appear on the home page and
          will return to the top of the feed until the job is filled or
          cancelled. Subscription can be cancelled anytime through the receipt
          emailed after purchase.
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            label="post a job"
            type="submit"
            color="#c08d5d"
            className={styles.button}
            disabled={!stripe || subscribing}
            submitting={subscribing}
          />
        </div>
      </form>
      <div className={styles.buttonWrapper}>
        <Button label="back to step 2" onClick={previous} />
      </div>
    </div>
  );
};

export default PaymentForm;
