import React from "react";

import Text from "../Text";

import styles from "./job-label.module.scss";

const JobLabel = ({ value }) => {
  return (
    <div className={styles.label}>
      <Text value={value} size={14} family="cerebriM" color="#c08d5d" />
    </div>
  );
};

export default JobLabel;
