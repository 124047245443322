import * as ActionTypes from "./actionTypes";
import { db } from "../../../firebase";
import { FIRESTORE_COLLECTIONS } from "../../../services/constants";

const getCompaniesStart = () => ({ type: ActionTypes.GET_COMPANIES_START });
const getCompaniesSuccess = (payload) => ({
  type: ActionTypes.GET_COMPANIES_SUCCESS,
  payload,
});
const getCompaniesFailure = (e) => ({
  type: ActionTypes.GET_COMPANIES_FAILURE,
  error: e.message,
});

const getCompanies = () => async (dispatch) => {
  try {
    dispatch(getCompaniesStart());
    const snapshot = await db.collection(FIRESTORE_COLLECTIONS.COMPANIES).get();
    if (snapshot.empty) {
      dispatch(getCompaniesSuccess({}));
      return;
    }
    const companies = {};
    snapshot.forEach((doc) => {
      companies[doc.id] = doc.data();
      console.log(doc.id, "=>", doc.data());
    });

    dispatch(getCompaniesSuccess(companies));
  } catch (error) {
    dispatch(getCompaniesFailure(error));
  }
};

export { getCompanies, getCompaniesSuccess };
