import React from "react";
import { Link } from "react-router-dom";

import styles from "./logo.module.scss";

const Logo = ({ src, url, className }) => (
  <Link
    to={url || "#"}
    className={className}
    onClick={(e) => (url ? {} : e.preventDefault())}
  >
    <img src={src} alt="" className={styles.logo} />
  </Link>
);

export default Logo;
