import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./article-summary.module.scss";
import Text from "../../components/Text";
import { formatDate } from "../../helpers/formats";
import { ReactComponent as CalendarSVG } from "../../assets/images/calendar.svg";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

function ArticleSummary({ article }) {
  const history = useHistory();
  return (
    <article
      className={styles.container}
      onClick={() => history.push(`${article.href}`)}
    >
      <div className={cx(styles.row, styles.space_betweeen)}>
        <Text
          type="h2"
          value={article.title}
          className={cx(styles.mr15, styles.width50)}
          family="cerebriM"
          size={16}
          color="#c08d5d"
        />
        <Text
          type="time"
          value={formatDate(article.createdAt.toDate())}
          iconLeft={
            <CalendarSVG
              width="15"
              height="15"
              fill={"#c08d5d"}
              style={{ marginRight: 5 }}
            />
          }
        />
      </div>
      <div className={cx(styles.row, styles.pd9)}>
        <Text type="p" value={article.spoiler} />
      </div>
    </article>
  );
}

export default ArticleSummary;
