import React, { useState } from "react";
import _ from "lodash";

import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import Job from "../../components/Job";

import styles from "./home.module.scss";
import Feedback from "../../components/Feedback";
import Button from "../../components/Button";
import SEO from "../../components/Seo";
import Loader from "../../components/Loader";
import { useFirestoreQuery } from "../../hooks";
import { db } from "../../firebase";
import { Context, removeFilter } from "../../store/FilterStore";
import { FIRESTORE_COLLECTIONS } from "../../services/constants";
import { toggleModal } from "../../redux/modules/modal/actions";
import { useDispatch } from "react-redux";

export default ({ location }) => {
  const { data: jobs, status } = useFirestoreQuery(
    db.collection(FIRESTORE_COLLECTIONS.JOBS).orderBy("createdAt", "desc")
  );
  const dispatch = useDispatch();
  const openModal = () => dispatch(toggleModal("loginModal"));

  return (
    <Layout location={location}>
      <SEO title="All remote jobs" />
      <Banner />
      <div className={styles.list}>
        {status === "loading" ? (
          <Loader color="#c08d5d" />
        ) : (
          <Context.Consumer>
            {({ store, dispatch }) => (
              <>
                {jobs && jobs.length > 0 ? (
                  applyFilter(jobs, store.data).map((job) => {
                    return (
                      <Job
                        key={job.id}
                        id={job.id}
                        {...job}
                        openModal={openModal}
                      />
                    );
                  })
                ) : (
                  <Feedback
                    value={
                      !_.isEmpty(store.data)
                        ? "There are no active jobs matching this filter."
                        : "There are no active jobs."
                    }
                  />
                )}
                {!_.isEmpty(store.data) && (
                  <Button
                    label="clear filters"
                    onClick={() => dispatch(removeFilter())}
                    className={styles.button}
                  />
                )}
              </>
            )}
          </Context.Consumer>
        )}
      </div>
    </Layout>
  );
};

const applyFilter = (jobs, filters) => {
  if (_.isEmpty(filters)) {
    return jobs;
  }
  let property = "";

  switch (filters.group) {
    case "regions":
      property = "restrictions";
      break;
    case "categories":
      property = "category";
      break;
    case "types":
      property = "type";
      break;
    case "date":
      property = "createdAt";
      break;
    case "name":
      property = "title";
      break;
    default:
      break;
  }

  if (
    property === "restrictions" ||
    property === "category" ||
    property === "type"
  ) {
    return jobs.filter((job) => job[property] === filters.filter);
  }
  if (property === "createdAt") {
    let arr,
      jobsByDate = {};
    switch (filters.filter) {
      case "1":
        arr = [
          ...Object.entries(jobs).sort(
            (x, y) => y[1].createdAt.seconds - x[1].createdAt.seconds
          ),
        ];
        break;
      case "2":
        arr = [
          ...Object.entries(jobs).sort(
            (x, y) => x[1].createdAt.seconds - y[1].createdAt.seconds
          ),
        ];
        break;
      default:
        break;
    }
    arr.map((job) => {
      delete job[1].hidden;
      return (jobsByDate[job[0]] = { ...job[1] });
    });
    return { ...{}, ...jobsByDate };
  } else if (property === "title") {
    let arr,
      jobsByTitle = {};
    switch (filters.filter) {
      case "1":
        arr = [
          ...Object.entries(jobs).sort((x, y) => {
            const nameX = x[1].title.toUpperCase();
            const nameY = y[1].title.toUpperCase();
            let comparison = 0;
            if (nameX > nameY) {
              comparison = 1;
            } else if (nameX < nameY) {
              comparison = -1;
            }
            return comparison;
          }),
        ];
        break;
      case "2":
        arr = [
          ...Object.entries(jobs).sort((x, y) => {
            const nameX = x[1].company.name.toUpperCase();
            const nameY = y[1].company.name.toUpperCase();
            let comparison = 0;
            if (nameX > nameY) {
              comparison = 1;
            } else if (nameX < nameY) {
              comparison = -1;
            }
            return comparison;
          }),
        ];
        break;
      default:
        break;
    }
    arr.map((job) => {
      delete job[1].hidden;
      return (jobsByTitle[job[0]] = { ...job[1] });
    });
    return { ...{}, ...jobsByTitle };
  }
};
