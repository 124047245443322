import React from "react";
import classNames from "classnames/bind";

import Text from "../Text";

import styles from "./input.module.scss";

const cx = classNames.bind(styles);

const Input = ({
  type,
  label,
  name,
  onChange,
  className,
  value,
  required,
  placeholder,
}) => {
  const inputContainerStyles = cx(styles.inputContainer, className);

  return (
    <div className={inputContainerStyles}>
      <div className={styles.inputLabel}>
        <Text value={label} size={14} color="#0b0b0b" family="cerebriM" />
        {required && <Text value="*" color="#c08d5d" family="cerebriM" />}
      </div>
      <input
        type={type || "text"}
        name={name || ""}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        className={styles.input}
      />
    </div>
  );
};

export default Input;
