const types = {
  1: "Full-time",
  2: "Part-time",
  3: "Contract",
};

const categories = {
  1: "Intern",
  2: "All Other",
  3: "Staff Accountant",
  4: "Management Accountant",
  5: "Finance Analyst",
  6: "Finance Partner/Controller",
  7: "Director",
  8: "CFO",
};

const regions = {
  1: "Asia",
  2: "Australia",
  3: "Europe",
  4: "Remote",
  5: "US",
};

export { categories, regions, types };
