import * as ActionTypes from "./actionTypes";

const initialState = {
  userLogged: false,
  data: {},
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.SET_USER_SUCCESS:
      return {
        ...state,
        userLogged: true,
        ...action.payload,
        isLoading: false,
      };
    case ActionTypes.SET_USER_FAILURE:
      return state;
    case ActionTypes.LOGOUT:
      return {
        ...state,
        userLogged: false,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
