import React from "react";
import { Route, useHistory } from "react-router-dom";

import { Auth } from "../../firebase.js";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  Auth().onAuthStateChanged(function (user) {
    if (!user) {
      history.push("/");
    }
  });

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;
