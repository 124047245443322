import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { toggleModal } from "../../redux/modules/modal/actions";

import { ReactComponent as WebsiteSVG } from "../../assets/images/website.svg";

import Text from "../Text";
import Logo from "../Logo";
import Button from "../Button";
import Avatar from "../Avatar";
import Loader from "../Loader";

import styles from "./company-card.module.scss";
import { Analytics } from "../../firebase";

const CompanyCard = ({ company, howToApply, companyId }) => {
  const dispatch = useDispatch();
  const userLogged = useSelector((state) => state.user.userLogged);

  const applyJob = () => {
    if (!userLogged) {
      dispatch(toggleModal("loginModal"));
      return;
    }
    Analytics.logEvent("applied", howToApply);
    window.location.replace(howToApply);
  };

  return (
    <div className={styles.card}>
      {!company ? (
        <Loader color="#c08d5d" center />
      ) : (
        <div className={styles.inner}>
          {company.logo ? (
            <Logo
              src={company.logo}
              url={`/company/${companyId}`}
              className={styles.mb10}
            />
          ) : (
            <Avatar
              name={company.name}
              size="md"
              url={`/company/${companyId}`}
              className={styles.mb10}
            />
          )}

          <Text
            value={company.hq}
            color="#c08d5d"
            size={30}
            family="cerebriM"
            align="center"
            className={styles.pb5}
          />

          <Text
            value={company.hq}
            color="#0b0b0b"
            size={16}
            family="cerebriM"
            className={styles.pb5}
          />

          <a
            href={company.website}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <Text
              value={company.website}
              color="#0b0b0b"
              size={14}
              family="cerebriL"
              iconLeft={
                <WebsiteSVG
                  width="17"
                  height="15"
                  fill={"#c08d5d"}
                  style={{ marginRight: 5 }}
                />
              }
            />
          </a>

          <Button
            label="Apply"
            size={14}
            className={styles.button}
            onClick={applyJob}
          />

          <Button
            label="view all jobs"
            size={14}
            className={styles.button}
            to={`/company/${companyId}`}
          />
        </div>
      )}
    </div>
  );
};

export default CompanyCard;
