import * as ActionTypes from "./actionTypes";

const initialState = {
  visible: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_MODAL:
      return {
        visible: action.payload,
      };
    case ActionTypes.HIDE_MODAL:
      return {
        visible: null,
      };
    default:
      return state;
  }
};

export default reducer;
