import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import Share from "../../components/Share";

import Loader from "../../components/Loader";
import { db } from "../../firebase";
import Text from "../../components/Text";
import { ReactComponent as CalendarSVG } from "../../assets/images/calendar.svg";
import JobLabel from "../../components/JobLabel";
import { formatDate } from "../../helpers/formats";

import styles from "./article-post.module.scss";
import Button from "../../components/Button";
import { FIRESTORE_COLLECTIONS } from "../../services/constants";

const ArticlePost = ({ ...props }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState(true);
  useEffect(() => {
    async function loadData() {
      const response = await db
        .collection(FIRESTORE_COLLECTIONS.ARTICLES)
        .doc(id)
        .get();
      let articleData = response.data();
      setArticle(articleData);
      setLoading(false);
    }

    loadData();
  }, []);

  return (
    <Layout {...props}>
      {loading ? (
        <Loader color="#c08d5d" center />
      ) : (
        <div className={styles.container}>
          <Text
            value={formatDate(article.createdAt.toDate())}
            iconLeft={
              <CalendarSVG
                width="15"
                height="15"
                fill={"#c08d5d"}
                style={{ marginRight: 5 }}
              />
            }
            className={styles.pb10}
          />
          <Text
            value={article.title}
            color="#c08d5d"
            size={22}
            family="cerebriM"
            className={styles.pb15}
          />
          {!_.isEmpty(article.tags) && (
            <div className={styles.row}>
              {article.tags.map((val) => (
                <JobLabel key={val} value={val} />
              ))}
            </div>
          )}
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: article.content }}
          />

          <div className={styles.buttons}>
            <Button
              label="Back"
              size={14}
              className={styles.button}
              to="/blog"
            />
          </div>
          <Share url="#" />
        </div>
      )}
    </Layout>
  );
};

export default ArticlePost;
