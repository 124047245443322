import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Analytics, firebase } from "../../firebase";

import { setJob } from "../../redux/modules/job/actions";

import styles from "./job-post.module.scss";

import Layout from "../../components/Layout";
import Steps from "./components/Steps";
import CreatePost from "./components/CreatePost";
import PreviewPost from "./components/PreviewPost";
import PaymentForm from "./components/PaymentForm";

const JobPost = ({ ...props }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const values = useSelector((state) => state.job);

  const goToNextStep = () => {
    setStep(step + 1);
  };

  const goToPreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = (values) => {
    dispatch(setJob(values));
  };

  useEffect(() => {
    const stepLog = {
      1: () => Analytics.logEvent(firebase.analytics.EventName.ADD_TO_CART),
      2: () => Analytics.logEvent(firebase.analytics.EventName.BEGIN_CHECKOUT),
      3: () =>
        Analytics.logEvent(firebase.analytics.EventName.ADD_PAYMENT_INFO),
    };
    stepLog[step]();
  }, [step]);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  return (
    <Layout {...props}>
      <div className={styles.container}>
        <Steps step={step} />

        {step === 1 && (
          <CreatePost
            next={goToNextStep}
            submit={handleSubmit}
            initialValues={values}
          />
        )}

        {step === 2 && (
          <PreviewPost
            next={goToNextStep}
            previous={goToPreviousStep}
            values={values}
          />
        )}

        {step === 3 && (
          <Elements stripe={stripePromise}>
            <PaymentForm previous={goToPreviousStep} />
          </Elements>
        )}
      </div>
    </Layout>
  );
};

export default JobPost;
