import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Analytics, db } from "../../firebase";
import { ReactComponent as CalendarSVG } from "../../assets/images/calendar.svg";

import Layout from "../../components/Layout";
import Text from "../../components/Text";
import Button from "../../components/Button";
import Share from "../../components/Share";
import Logo from "../../components/Logo";
import CompanyCard from "../../components/CompanyCard";
import Avatar from "../../components/Avatar";
import JobLabel from "../../components/JobLabel";
import { categories, regions, types } from "../../helpers/job";

import styles from "./position.module.scss";
import { isLoggedIn } from "../../helpers/auth";
import Loader from "../../components/Loader";
import { formatDate } from "../../helpers/formats";
import { FIRESTORE_COLLECTIONS } from "../../services/constants";

const Job = ({ ...props }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState(true);
  const [job, setJob] = useState(true);
  useEffect(() => {
    async function loadData() {
      const response = await db
        .collection(FIRESTORE_COLLECTIONS.JOBS)
        .doc(id)
        .get();
      let jobData = response.data();
      setJob(jobData);
      const company = await jobData.companyId.get();
      setCompany({ id: company.refID, ...company.data() });
      setLoading(false);
    }
    loadData();
  }, []);
  const [visible, setVisible] = useState(null);
  const showModal = () => {
    setVisible(true);
  };

  const applyJob = () => {
    if (!isLoggedIn()) {
      showModal();
      return;
    }

    Analytics.logEvent("applied", job.howToApply);
    window.location.replace(job.howToApply);
  };

  return (
    <Layout {...props} visible={visible}>
      {loading ? (
        <Loader color="#c08d5d" center />
      ) : (
        <div className={styles.container}>
          <CompanyCard
            company={company}
            companyId={company.id}
            showModal={showModal}
          />
          {company.logo ? (
            <Logo
              src={company.logo}
              url={{
                pathname: `/company/${company.id}`,
                state: { companyId: company.id },
              }}
              className={styles.mb10}
            />
          ) : (
            <Avatar
              name={company.name}
              size="md"
              url={{
                pathname: `/company/${company.id}`,
                state: { companyId: company.id },
              }}
              className={styles.mb10}
            />
          )}

          <Text
            value={formatDate(job.createdAt.toDate())}
            iconLeft={
              <CalendarSVG
                width="15"
                height="15"
                fill={"#c08d5d"}
                style={{ marginRight: 5 }}
              />
            }
            className={styles.pb10}
          />

          <Text
            value={job.title}
            color="#c08d5d"
            size={22}
            family="cerebriM"
            className={styles.pb15}
          />

          <div className={styles.row}>
            <JobLabel value={types[job.type]} />
            <JobLabel value={categories[job.category]} />
            <JobLabel value={regions[job.restrictions]} />
          </div>

          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: job.description }}
          />

          <div className={styles.buttons}>
            <Button
              label="All Jobs"
              size={14}
              className={styles.button}
              to="/"
            />
            <Button
              label="Apply"
              size={14}
              className={styles.button}
              onClick={applyJob}
            />
          </div>

          <Share url="#" />
        </div>
      )}
    </Layout>
  );
};

export default Job;
