import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import { regions } from "../../helpers/job";
import { setCreatedDate } from "../../helpers/formats";
import Text from "../Text";

import { ReactComponent as ContactSVG } from "../../assets/images/paper.svg";
import { ReactComponent as LocationSVG } from "../../assets/images/pin.svg";
import { ReactComponent as CalendarSVG } from "../../assets/images/calendar.svg";

import styles from "./job.module.scss";

const cx = classNames.bind(styles);

const Job = ({ id, ...props }) => {
  const milliseconds = new Date(props.createdAt.seconds * 1000);
  const [company, setCompany] = useState({});

  useEffect(() => {
    async function getCompany() {
      const company = await props.companyId.get();
      setCompany({ id: company.refID, ...company.data() });
    }
    getCompany();
  }, []);

  return (
    <Link to={{ pathname: `/job/${id}` }} className={styles.container}>
      <div className={cx(styles.row, styles.space_betweeen, styles.pd9)}>
        <div style={{ display: "flex" }}>
          {company && company.logo && (
            <img src={company.logo} alt={props.name} className={styles.logo} />
          )}
        </div>
        <Text
          value={setCreatedDate(milliseconds)}
          iconLeft={
            <CalendarSVG
              width="15"
              height="15"
              fill={"#c08d5d"}
              style={{ marginRight: 5 }}
            />
          }
        />
      </div>
      <div className={cx(styles.row, styles.pd8)}>
        <Text value={company && company.name} />
      </div>
      <div className={cx(styles.row, styles.pd9)}>
        <Text value={props.title} family="cerebriM" size={16} color="#c08d5d" />
      </div>
      <div className={cx(styles.row, styles.space_betweeen)}>
        <Text
          value={company && company.email}
          size={14}
          iconLeft={
            <ContactSVG
              width="15"
              height="15"
              fill={"#c08d5d"}
              style={{ marginRight: 5 }}
            />
          }
          className={cx(styles.mr15, styles.width50)}
        />
        <Text
          value={regions[props.restrictions]}
          size={14}
          iconLeft={
            <LocationSVG
              width="15"
              height="15"
              fill={"#c08d5d"}
              style={{ marginRight: 5 }}
            />
          }
        />
      </div>
    </Link>
  );
};

export default Job;
