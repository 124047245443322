import React from "react";
import classNames from "classnames/bind";
import styles from "./text.module.scss";

const cx = classNames.bind(styles);

const Heading = ({ text }) => <h2>{text}</h2>;
const Paragraph = ({ text }) => <p>{text}</p>;
const Time = ({ text }) => <time>{text}</time>;
const Plain = ({ text }) => <span>{text}</span>;
const TextType = {
  h2: Heading,
  time: Time,
  p: Paragraph,
  plain: Plain,
};
const Text = ({
  type = "plain",
  value,
  className,
  textClass,
  family,
  size,
  color,
  align,
  iconLeft,
  iconLeftStyle,
  iconRight,
  iconRightStyle,
}) => {
  const containerStyles = cx(styles.container, className);

  const textStyles = {
    width: "100%",
    fontFamily: family,
    fontSize: `${size}px`,
    color: color,
    textAlign: align,
  };
  const TextElementType = TextType[type];
  return (
    <div className={containerStyles}>
      {iconLeft && <div className={iconLeftStyle}>{iconLeft}</div>}
      <div className={cx(styles.text, textClass)} style={textStyles}>
        <TextElementType text={value} />
      </div>
      {iconRight && <div className={iconRightStyle}>{iconRight}</div>}
    </div>
  );
};

Text.defaultProps = {
  size: 16,
  color: "#0b0b0b",
  align: "left",
  family: "cerebriL",
};

export default Text;
