import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCHUTfO48Vopg35YYSuGvXkbZtbKCGHRq4",
  authDomain: "remote-accounting.firebaseapp.com",
  databaseURL: "https://remote-accounting.firebaseio.com",
  projectId: "remote-accounting",
  storageBucket: "remote-accounting.appspot.com",
  messagingSenderId: "948169042866",
  appId: "1:948169042866:web:37629a7100d574c864d2ab",
  measurementId: "G-KXSLBLDM9K",
};

// Initialize Firebase
let Firebase = firebase.initializeApp(firebaseConfig);

let Analytics = {
  setCurrentScreen: (...args) => console.log(`setCurrentScreen => ${args}`),
  logEvent: (...args) => console.log(`logEvent => ${JSON.stringify(args)}`),
  setUserId: (...args) => console.log(`setUserId => ${args}`),
};

if (process.env.REACT_APP_ENVIRONMENT === "prod") {
  Analytics = firebase.analytics();
}

const Auth = firebase.auth;
const auth = firebase.auth();
const storage = firebase.storage();
const db = firebase.firestore();
// avoid deprecated warnings
db.settings({});

export { db, Auth, auth, storage, Analytics, Firebase, firebase };
