import React from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";

import Text from "../Text";

import styles from "./avatar.module.scss";

const cx = classNames.bind(styles);

const Avatar = ({ name, size, className, url }) => {
  const letter = name && name.substring(0, 1);

  return (
    <Link
      to={url || "#"}
      className={cx(styles.avatar, styles[size], className)}
      onClick={(e) => (url ? {} : e.preventDefault())}
    >
      <Text
        value={letter}
        size={size === "sm" ? 13 : 26}
        family="cerebriM"
        color="#ffffff"
      />
    </Link>
  );
};

export default Avatar;
