import React, { Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { Analytics } from "./firebase";

import PrivateRoute from "./components/PrivateRoute";
import Home from "./routes/Home";
import Auth from "./routes/Auth";
import Job from "./routes/Job";
import JobPost from "./routes/JobPost";
import Company from "./routes/Company";
import BlogIndexPage from "./routes/Blog/blog-index-page";
import ArticlePost from "./routes/Blog/article-post";

function FirebaseAnalytics() {
  let location = useLocation();
  useEffect(() => {
    const page_path = location.pathname + location.search;
    Analytics.setCurrentScreen(page_path);
    Analytics.logEvent("page_view", { page_path });
  }, [location]);
  return null;
}

export default class App extends Component {
  render() {
    return (
      <Router>
        <FirebaseAnalytics />
        <Switch>
          <Route path="/auth/:token" component={Auth} />
          <Route path="/blog/:id" component={ArticlePost} />
          <Route path="/blog" component={BlogIndexPage} />
          <Route path="/company/:id" component={Company} />
          <Route path="/job/:id" component={Job} />
          <PrivateRoute path="/post-job" component={JobPost} />
          <Route exact path="/" component={Home} />
        </Switch>
      </Router>
    );
  }
}
