export const STORAGE_KEYS = {
  INACTIVITY_TIMER: "inactivity_timer",
  EXPIRES_AT: "expires_at",
  REFRESH_TOKEN: "refresh_token",
  ID_TOKEN: "id_token",
  COUNTDOWN_OPEN: "countdown_open",
};

export const FIRESTORE_COLLECTIONS = {
  JOBS: `jobs_${process.env.REACT_APP_ENVIRONMENT}`,
  ARTICLES: `articles_${process.env.REACT_APP_ENVIRONMENT}`,
  COMPANIES: `companies_${process.env.REACT_APP_ENVIRONMENT}`,
  USERS: "users",
};
