import DAO from "./BaseDAO";

class ApiDAO {
  constructor() {
    this.DAO = new DAO({ apiUrl: `${process.env.REACT_APP_BASE_API_URL}` });
  }

  async createSubscription(data) {
    let response;
    try {
      response = await this.DAO.apiRequest({
        url: "subscriptions",
        method: "POST",
        data,
      });
    } catch (e) {
      console.error(e);
      response = { errors: e.errors ? e.errors : e.message };
    }
    return response;
  }

  async postJob(data) {
    let response;
    try {
      response = await this.DAO.apiRequest({
        url: "jobs",
        method: "POST",
        data,
      });
    } catch (e) {
      response = { errors: e.errors ? e.errors : e.message };
    }
    return response;
  }
}

export default new ApiDAO();
