import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import classNames from "classnames/bind";

import Text from "../Text";

import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from "./textarea.module.scss";

const cx = classNames.bind(styles);

class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
    const html = props.value || "";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  onEditorStateChange = (editorState) => {
    const { onChange } = this.props;
    this.setState({
      editorState,
    });
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  setFocus = () => {
    const { focused } = this.state;
    this.setState({ focused: !focused });
  };

  render() {
    const { editorState, focused } = this.state;
    const { label, required } = this.props;

    return (
      <div className={styles.textareaContainer}>
        <div className={styles.textareaLabel}>
          <Text value={label} size={14} color="#0b0b0b" family="cerebriM" />
          {required && <Text value="*" color="#c08d5d" family="cerebriM" />}
        </div>
        <Editor
          editorState={editorState}
          editorClassName={cx(styles.editor, focused && styles.focused)}
          toolbarClassName={styles.toolbar}
          onFocus={this.setFocus}
          onBlur={this.setFocus}
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}

export default Textarea;
