import * as ActionTypes from "./actionTypes";

const initialState = {
  data: {},
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_JOBS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ActionTypes.GET_JOBS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null,
      };
    case ActionTypes.GET_JOBS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ActionTypes.GET_FILTER_JOBS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.GET_FILTER_JOBS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
