import { createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import * as modules from "./modules";

const userInitialState = {
  userLogged: false,
  data: {},
  isLoading: false,
};

const appReducer = combineReducers({
  ...modules,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state.user = userInitialState;
  }
  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(ReduxThunk))
);

export default store;
