import React from "react";
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

import Text from "../Text";

import styles from "./share.module.scss";

import { ReactComponent as FacebookSVG } from "../../assets/images/facebook.svg";
import { ReactComponent as TwitterSVG } from "../../assets/images/twitter.svg";
import { ReactComponent as LinkedinSVG } from "../../assets/images/linkedin.svg";
import { ReactComponent as InstagramSVG } from "../../assets/images/instagram.svg";

const Share = ({ url }) => {
  const button = {
    backgroundColor: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 30,
    cursor: "pointer",
    marginRight: 10,
    outline: "none",
    boxShadow: "none",
  };

  return (
    <div className={styles.container}>
      <Text
        value="Share:"
        color="#0b0b0b"
        size={16}
        family="cerebriL"
        className={styles.pb5}
      />
      <div className={styles.row}>
        <FacebookShareButton url={url} style={button}>
          <FacebookSVG width="20" height="20" fill={"#ffffff"} />
        </FacebookShareButton>
        <LinkedinShareButton url={url} style={button}>
          <LinkedinSVG width="20" height="20" fill={"#ffffff"} />
        </LinkedinShareButton>
        <InstapaperShareButton url={url} style={button}>
          <InstagramSVG width="20" height="20" fill={"#ffffff"} />
        </InstapaperShareButton>
        <TwitterShareButton url={url} style={button}>
          <TwitterSVG width="20" height="20" fill={"#ffffff"} />
        </TwitterShareButton>
      </div>
    </div>
  );
};

export default Share;
