import * as ActionTypes from "./actionTypes";

const showModal = (payload) => ({
  type: ActionTypes.SHOW_MODAL,
  payload,
});

const hideModal = () => ({
  type: ActionTypes.HIDE_MODAL,
});

const toggleModal = (modal) => (dispatch, getState) => {
  const visible = getState().modal.visible;
  if (visible) {
    dispatch(hideModal());
  } else {
    dispatch(showModal(modal));
  }
};

export { toggleModal };
