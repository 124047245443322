import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { toggleModal } from "../../redux/modules/modal/actions";

import Button from "../Button";
import Text from "../Text";

import styles from "./banner.module.scss";

const Banner = ({ title = "REMOTE ACCOUNTING" }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userLogged = useSelector((state) => state.user.userLogged);
  const openModal = () => dispatch(toggleModal("loginModal"));

  return (
    <div className={styles.container}>
      <Text
        value={title}
        color="#c08d5d"
        size={24}
        family="cerebriM"
        className={styles.pb15}
      />
      <Text
        value={`Find a remote job today`}
        color="#0b0b0b"
        size={14}
        align="center"
        className={styles.subtitle}
      />
      <Button
        label="post a job"
        onClick={() => (userLogged ? history.push("/post-job") : openModal())}
      />
    </div>
  );
};

export default Banner;
